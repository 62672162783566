/* eslint-disable */
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, gql } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'
import { onError } from "@apollo/client/link/error";

export const clientMutation = async (query, variables, endpoint, headers) => {
    let token = localStorage.getItem('token');
    const link = new createUploadLink({ uri: endpoint });

    let errorData = [];
    let returnData = null;
    let returnError = null;

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: headers || {
                authorization: 'bearer ' + token,
                'Apollo-Require-Preflight': 'true'
            },
        });

        // Call the next link in the middleware chain.
        return forward(operation);
    });

    const client = new ApolloClient({
        link: authLink.concat(
            ApolloLink.from([
                onError(({ response, graphQLErrors, networkError, operation, forward }) => {
                    console.log('graphQLErrors', graphQLErrors, response);
                    errorData = response;
                    graphQLErrors &&
                        graphQLErrors.map((error) => {
                            //if (error.errorType === 'UnauthorizedException') refreshToken = true;
                        });
                }),
                link,
            ]),
        ),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                errorPolicy: 'all',
            },
        },
    });

    await client
        .mutate({
            mutation: gql(query),
            variables: variables,
            context: {
                fetchOptions: {
                    onUploadProgress: (progress) => { },
                },
            },
        })
        .then((res) => {
            returnData = res.data;
        })
        .catch((error) => {
            returnError = error;
        });

    return new Promise((resolve, reject) => {
        if (!returnError) return resolve(returnData);
        else {
            return reject({
                error: returnError.message,
                innerError: returnError,
                errorData: errorData,
                //logout: returnCall && returnError && refreshToken,
            });
        }
    });
};

export const clientQuery = async (query, variables, endpoint, headers) => {
    let token = localStorage.getItem('token');

    let errorData = [];
    let returnData = null;
    let returnError = null;

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: headers || {
                authorization: 'bearer ' + token,
            },
        });

        // Call the next link in the middleware chain.
        return forward(operation);
    });

    const client = new ApolloClient({
        link: authLink.concat(
            ApolloLink.from([
                onError(({ response, graphQLErrors, networkError, operation, forward }) => {
                    console.log('graphQLErrors', graphQLErrors, response);
                    errorData = response;
                    graphQLErrors &&
                        graphQLErrors.map((error) => {
                            //if (error.errorType === 'UnauthorizedException') refreshToken = true;
                        });
                }),
                new HttpLink({
                    uri: endpoint,
                }),
            ]),
        ),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                //errorPolicy: 'all'
            },
        },
    });

    await client
        .query({
            query: gql(query),
            variables: variables,
        })
        .then((res) => {
            returnData = res.data;
        })
        .catch((error) => {
            returnError = error;
        });


    return new Promise((resolve, reject) => {
        if (!returnError) return resolve(returnData);
        else {
            return reject({
                error: returnError.message,
                innerError: returnError,
                errorData: errorData,
                //logout: returnCall && returnError && refreshToken,
            });
        }
    });
};