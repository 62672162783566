
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension';

// ...
import reducers from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware()
const enhancer = composeWithDevTools(
  applyMiddleware(sagaMiddleware, /*other middleware*/),
  /* other store enhancers if any */
);

const store = createStore(
    reducers,
    enhancer
)
sagaMiddleware.run(rootSaga)

export default store;