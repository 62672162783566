import { forwardRef } from "react"
import { Dropdown } from 'react-bootstrap'
import cx from 'classnames'

export const useDropdownCustomToggle = () => {

   const CustomToggle = forwardRef(({ children, onClick, className }, ref) => {
      className = className.replace("dropdown-toggle", "")
      return (
         <div
            className={className}
            ref={ref}
            onClick={(e) => {
               e.preventDefault();
               onClick(e);
            }}
         >
            {children}
         </div>
      )
   });

   const DropdownToggle = ({ children, align, variant, className }) => {
      return (
         <Dropdown.Toggle className={cx("cursor-pointer", className)} as={CustomToggle} variant={variant} align={align}>
            {children}
         </Dropdown.Toggle>
      )
   }

   return DropdownToggle
}