

export const ExceptionManager = (exc, module, method, show = false) => {
	try {
		console.log(`Error => Module: ${module} \nMethod: ${method} \nexc:`, exc);

		if (exc.errorData && exc.errorData.errors) {
			/*exc.errorData.errors.forEach(error => {
				if (error.errorInfo && error.errorInfo.code) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error',
						error.errorInfo?.code
					);
				}
				else if (show) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error'
					);
				}
			});*/
		}
	}
	catch (exc2) {
		console.log("ExceptionManager error: ", exc2)
	}
};

export const LogManager = (...log) => {
	console.log('Log => ', ...log);
};
