import { useCallback } from "react"

export const useLogManager = () => {

    const _console = useCallback((key, ...log) => {
        let debug = Boolean(localStorage.getItem("log")) === true;
        let logKeys = localStorage.getItem("logKeys")
        logKeys = logKeys && JSON.parse(logKeys) || []

        if (debug && (logKeys.length == 0 || logKeys.includes(key)))
            console.log(`Log ${key}=>`, ...log);
    })

    const _error = useCallback((exc, module, method) => {
        try {
            console.log(`Error => Module: ${module} \nMethod: ${method} \nexc:`, exc);

            // if (exc.errorData && exc.errorData.errors) {
            //     exc.errorData.errors.forEach(error => {
            //         if (error.errorInfo && error.errorInfo.code && show) {
            //             NotificationManager.error(
            //                 ErrorHandlerLogin(error.message),
            //                 "Error",
            //                 3000,
            //                 null,
            //                 null,
            //                 'error',
            //                 error.errorInfo?.code
            //             );
            //         }
            //         else if (show) {
            //             NotificationManager.error(
            //                 ErrorHandlerLogin(error.message),
            //                 "Error",
            //                 3000,
            //                 null,
            //                 null,
            //                 'error'
            //             );
            //         }
            //     });
            // }
        }
        catch (exc2) {
            console.log("ExceptionManager error: ", exc2)
        }
    })

    return {
        console: _console,
        error: _error
    }


}